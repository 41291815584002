import React from "react"
import * as styles from "./FullStackCombination.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const LaravelCombination = ({ strapiData }) => {
  return (
    <div className={styles.angularCombination}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`gap-30 justify-content-center ${styles.cardWrapper}`}>
          {strapiData?.cards &&
            strapiData?.cards?.map((e, i) => (
              <Col xl={6} md={6} xs={12} key={i} className={styles.Golang}>
                <div className={styles.card}>
                  <div className={styles.imgs}>
                    <GatsbyImage
                      image={getImage(e?.image1 && e?.image1[0]?.localFile)}
                      alt={e?.title}
                      placeholder="blurred"
                      decoding="async"
                      loading="lazy"
                    />
                  </div>

                  <h3>{e?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  )
}

export default LaravelCombination
